import { Stack, Typography } from "@mui/material";
import { GridColDef, GridGroupingColDefOverride } from "@mui/x-data-grid-premium";
import FileIcon from "../../../shared/components/FileIcon";
import { convertISODate } from "../../../shared/utilities/dateUtils";
import { formatFileSize, getFileExtension } from "../../../shared/utilities/fileHelper";
import { FundraisingFile } from "../../api/types/fundraisingTypes";
import { useFundraisingDocumentActionsContext } from "./FundraisingDocumentActionsContext";

const FileNameCell = ({ row }: { row: FundraisingFile }) => {
  const { showFilePreview } = useFundraisingDocumentActionsContext();

  if (!row.name) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center" width="100%" pl={5}>
      <FileIcon fileExtension={getFileExtension(row.name)} />
      <Typography
        noWrap
        sx={(t) => ({ cursor: "pointer", "&:hover": { color: t.palette.primary.main } })}
        onClick={() => showFilePreview(row.fileId)}
      >
        {row.name}
      </Typography>
    </Stack>
  );
};

export const groupingColumnDefinition: GridGroupingColDefOverride<FundraisingFile> = {
  leafField: "name",
  hideDescendantCount: true,
  flex: 1,
};

export const columnDefinitions: GridColDef<FundraisingFile>[] = [
  {
    field: "section",
    headerName: "",
    renderCell: ({ rowNode, field, value }) => {
      if (rowNode.type === "group" && field === rowNode.groupingField) {
        return "";
      }

      return <Typography variant="subtitle2">{value}</Typography>;
    },
  },
  {
    field: "name",
    headerName: "Name",
    renderCell: ({ row }) => <FileNameCell row={row} />,
  },
  {
    field: "size",
    headerName: "Size",
    type: "number",
    width: 120,
    valueFormatter: (value: number) => (value ? formatFileSize(value) : null),
  },
  {
    field: "publishedAt",
    headerName: "Date",
    width: 160,
    valueFormatter: (value: string) => (value ? convertISODate(value) : ""),
  },
];
