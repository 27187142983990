import { useCallback, useEffect } from "react";
import documentEventsApi, { DocumentViewEvent, PageViewEventData } from "../../../api/clients/documentEventsApi";
import useIsUserActive from "../../../hooks/useIsUserActive";
import useSetInterval from "../../../hooks/useSetInterval";
import { logError } from "../../../logging";
import { pageViewTracker } from "./pageViewTracker";

interface PageViewTrackingHookParams {
  sessionId: string | undefined;
  numPages: number;
  currentPageNumber: number;
}

const persistPageViews = async (pageViews: DocumentViewEvent<PageViewEventData>[]) => {
  if (pageViews.length === 0) {
    return;
  }

  try {
    await documentEventsApi.ingestDocumentPageViewEvents({ viewEventsData: pageViews });
  } catch (error) {
    logError(error, "[PageViewTracker]");
  }
};

const usePageViewTracking = ({ sessionId, numPages, currentPageNumber }: PageViewTrackingHookParams) => {
  useEffect(() => {
    if (sessionId === undefined) {
      return;
    }

    pageViewTracker.startSession(sessionId, numPages);

    return () => {
      persistPageViews(pageViewTracker.eventsBufferToPageViews());
      pageViewTracker.stopSession();
    };
  }, [numPages, sessionId]);

  useEffect(() => {
    if (sessionId === undefined) {
      return;
    }

    if (currentPageNumber > 0) {
      pageViewTracker.trackPageViewStart(currentPageNumber);
    }
  }, [currentPageNumber, sessionId]);

  const onUserActiveChange = useCallback(
    (isUserActive: boolean) => {
      if (sessionId === undefined) {
        return;
      }

      if (isUserActive) {
        pageViewTracker.trackPageViewStart(currentPageNumber);
      } else {
        pageViewTracker.trackPageViewStop(currentPageNumber);
      }
    },
    [currentPageNumber, sessionId]
  );

  useIsUserActive(onUserActiveChange);

  const onTick = useCallback(() => {
    if (sessionId === undefined) {
      return;
    }

    persistPageViews(pageViewTracker.eventsBufferToPageViews());
  }, [sessionId]);

  useSetInterval(onTick, 12_000);
};

export default usePageViewTracking;
