import { PropsWithChildren } from "react";
import { useClientContext } from "../../contexts/ClientContext";
import { InvestorRelationPortalFeatures } from "../../store/state/user/types";

interface Props {
  required?: InvestorRelationPortalFeatures;
  requiredAbsence?: InvestorRelationPortalFeatures;
}

const FeatureBoundary = ({ required, requiredAbsence, children }: PropsWithChildren<Props>) => {
  const { isFeatureEnabled } = useClientContext();
  const renderContent =
    (required === undefined || isFeatureEnabled(required)) &&
    (requiredAbsence === undefined || !isFeatureEnabled(requiredAbsence));
  return renderContent ? <>{children}</> : null;
};

export default FeatureBoundary;
