import PersonIcon from "@mui/icons-material/PersonOutline";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  TabularDataFieldConfigMap,
  communicationGroupNameField,
  getBalanceFieldSet,
  investorNameField,
  netIRRField,
} from "../../../../shared/components/balances/balanceFieldset";
import { TabularDataField } from "../../../../shared/reporting/api/biClient.types";
import { groupByToMap } from "../../../../shared/utilities/arrayHelper";
import { useClientContext } from "../../../contexts/ClientContext";
import { useLocalization } from "../../../hooks/useLocalization";
import JCurveIcon from "../../../icons/JCurveIcon";
import { BalanceDataResponse } from "../../../store/state/dashboard/types";
import FundCellComponent from "./FundCellComponent";

interface BalanceConfigProps {
  showIrrChart: boolean;
}

interface Props extends BalanceConfigProps {
  balanceDataResponse: BalanceDataResponse;
}

interface RowProps extends BalanceConfigProps {
  fields: TabularDataFieldConfigMap[];
}

interface TitleRowProps {
  title: string;
  fieldsCount: number;
}

interface BalanceRowsProps extends RowProps {
  rowsData: {
    [key: string]: string;
  }[];
  onRowClick: (row: { [key: string]: string }) => void;
  reportingDates: Record<string, Date>;
}

interface TotalRowProps extends RowProps {
  totals: Record<string, number>;
}

const BalanceFieldComponents = {
  [communicationGroupNameField.guid]: FundCellComponent,
};

const getFieldName = (field: TabularDataField) => {
  switch (field.fieldType) {
    case "Dimension":
      return field.dimension?.dimensionName;
    case "Measure":
      return field.measure?.measureName;
    default:
      return field.guid;
  }
};

const BalanceTable = ({ balanceDataResponse, showIrrChart }: Props) => {
  const fields = getBalanceFieldSet(false);
  const rowsGrouped = useMemo(
    () =>
      groupByToMap(
        balanceDataResponse.balanceData ? balanceDataResponse.balanceData.data : [],
        (data) => data[investorNameField.guid]
      ),
    [balanceDataResponse.balanceData]
  );
  const { clientCode } = useClientContext();
  const navigate = useNavigate();
  const reportingDates = balanceDataResponse.fundReportingDates;

  const handleRowClick = (row: { [key: string]: string }) => {
    return; //disable until page is ready
    navigate(
      `/${clientCode}/dashboard/fund?${fields
        .filter((f) => f.config?.isKey)
        .map((f) => `${getFieldName(f)}=${encodeURIComponent(row[f.guid] as string)}`)
        .join("&")}`
    );
  };

  const group = Array.from(rowsGrouped.keys());

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <HeaderRow showIrrChart={showIrrChart} fields={fields} />
        </TableHead>
        <TableBody>
          {group.map((title, i) => {
            const rowsData = rowsGrouped.get(title);
            const groupTitle = title || investorNameField.guid;
            return (
              <Fragment key={i}>
                {i > 0 && <EmptyRow key={`er_${i}`} />}
                <TitleRow key={`tr_${i}`} title={groupTitle} fieldsCount={fields.length + 1} />
                {rowsData && (
                  <BalanceRows
                    key={`brs_${i}`}
                    fields={fields}
                    rowsData={rowsData}
                    onRowClick={handleRowClick}
                    reportingDates={reportingDates}
                    showIrrChart={showIrrChart}
                  />
                )}
              </Fragment>
            );
          })}
          {group.length === 0 && (
            <EmptyRow
              key="er"
              showNoDataMsg
              colSpan={fields.filter((f) => !f.config?.hidden).length + (showIrrChart ? 3 : 2)}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const BalanceRows = ({ fields, rowsData, onRowClick, reportingDates, showIrrChart }: BalanceRowsProps) => {
  const getNetIrrValue = (row: { [key: string]: string }): number => {
    const valueFromRow = row[netIRRField.guid];
    if (valueFromRow) {
      return parseFloat(valueFromRow);
    }
    return 0;
  };

  const totals: Record<string, number> = {};
  return (
    <>
      {rowsData.map((row, i) => {
        return (
          <Fragment key={i}>
            <TableRow hover key={`br_${i}`} onClick={() => onRowClick(row)}>
              <TableCell key="tec"></TableCell>
              {fields.map((field) => {
                const value = row[field.guid] as string;
                const config = field.config;
                if (config?.hidden) {
                  return <TableCell key={`hc_${i}`} width={0} />;
                }
                const formattedValue = config?.format ? config.format(value) : value || "";
                if (rowsData.length > 1 && value && config?.calculateTotal) {
                  const numValue = +value;
                  if (isNaN(numValue)) {
                    throw new Error(`Can't calculate total for non number value ${value}. FieldId: ${field.guid}`);
                  }
                  totals[field.guid] = (totals[field.guid] || 0) + numValue;
                }

                const components = Object.entries(BalanceFieldComponents);
                const componentToRender = components.find(([key]) => key === field.guid)?.[1];
                const fundReportingDate = reportingDates[value];

                return (
                  <TableCell align={config?.align || "left"} key={`brc_${field.guid}_${i}`}>
                    {componentToRender ? (
                      componentToRender({
                        value: formattedValue,
                        reportingDate: fundReportingDate && new Date(fundReportingDate),
                      })
                    ) : (
                      <Typography>{formattedValue}</Typography>
                    )}
                  </TableCell>
                );
              })}
              {showIrrChart && (
                <TableCell key="irr">
                  <JCurveIcon progress={getNetIrrValue(row)} />
                </TableCell>
              )}
            </TableRow>
          </Fragment>
        );
      })}
      {rowsData.length > 1 && Object.entries(totals).length > 0 && (
        <TotalRow showIrrChart={showIrrChart} key="btr" fields={fields} totals={totals} />
      )}
    </>
  );
};

const TitleRow = ({ title, fieldsCount }: TitleRowProps) => {
  return (
    <TableRow key="mtr">
      <TableCell padding="checkbox">
        <PersonIcon color="primary" />
      </TableCell>
      <TableCell colSpan={fieldsCount}>
        <Typography variant="subtitle1" sx={(theme) => ({ color: theme.palette.primary.main })}>
          {title}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const HeaderRow = ({ fields, showIrrChart }: RowProps) => (
  <TableRow key="bhr">
    <TableCell key="ico" padding="checkbox" />
    {fields.map((field) => {
      const config = field.config;
      if (config?.hidden) {
        return <TableCell width={0} key={field.guid} />;
      }
      return (
        <TableCell align={config?.align || "left"} sx={{ whiteSpace: "nowrap" }} key={field.guid}>
          {config?.title || getFieldName(field)}
        </TableCell>
      );
    })}
    {showIrrChart && (
      <TableCell sx={{ whiteSpace: "nowrap" }} key="irr">
        Net IRR Chart
      </TableCell>
    )}
  </TableRow>
);

const TotalRow = ({ fields, totals, showIrrChart }: TotalRowProps) => (
  <TableRow hover key="balance_tr">
    <TableCell key="icotr" padding="checkbox" />
    {fields.map((field) => {
      const config = field.config;
      const value = totals[field.guid];
      const formattedValue = value && config?.format ? config.format(value) : value || "";
      return (
        <TableCell align={config?.align || "left"} key={field.guid}>
          <Typography sx={{ fontWeight: "bold" }}>{formattedValue}</Typography>
        </TableCell>
      );
    })}
    {showIrrChart && <TableCell key="irr"></TableCell>}
  </TableRow>
);

const EmptyRow = ({ showNoDataMsg, colSpan }: { showNoDataMsg?: boolean; colSpan?: number }) => {
  const locale = useLocalization().dashboard.balance;
  return (
    <TableRow
      key="emr"
      sx={(theme) => ({
        height: theme.spacing(4),
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: theme.palette.divider,
      })}
    >
      {showNoDataMsg && (
        <TableCell align="center" colSpan={colSpan || 0}>
          <Typography color="text.secondary">{locale.no_data}</Typography>
        </TableCell>
      )}
    </TableRow>
  );
};

export default BalanceTable;
