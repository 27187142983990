import { SxProps, Theme, Tooltip, TooltipProps, Typography, TypographyProps } from "@mui/material";
import { useCallback, useRef, useState } from "react";

interface Props {
  text: string;
  tooltipProps?: Partial<TooltipProps>;
  tooltipPlacement?: "top" | "bottom" | "left" | "right";
  typographyProps?: TypographyProps;
  typographySx?: SxProps<Theme>;
}

const TypographyTooltipEllipsis = ({ text, tooltipProps, tooltipPlacement, typographyProps, typographySx }: Props) => {
  const ref = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleShowTooltip = useCallback(() => {
    if (!ref.current) {
      return;
    }
    const el = ref.current as HTMLSpanElement;
    const isOverflow = el.offsetWidth < el.scrollWidth;
    setShowTooltip(isOverflow);
  }, []);

  const renderContent = () => (
    <Typography
      ref={ref}
      onMouseEnter={handleShowTooltip}
      {...typographyProps}
      sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", ...typographySx }}
    >
      {text}
    </Typography>
  );

  return (
    <>
      {ref.current && showTooltip && text ? (
        <Tooltip title={text} placement={tooltipPlacement} arrow sx={tooltipProps}>
          {renderContent()}
        </Tooltip>
      ) : (
        renderContent()
      )}
    </>
  );
};

export default TypographyTooltipEllipsis;
