import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { withErrorHandling } from "../../../shared/api/axiosHelper";
import Breakpoint from "../../../shared/components/Breakpoint";
import PreviewFileDialog from "../../../shared/components/previewFile/PreviewFileDialog";
import { useNotificationContext } from "../../../shared/contexts/NotificationContext";
import { logError } from "../../../shared/logging";
import { downloadFileFromUrl, downloadFileFromUrlViaAxios } from "../../../shared/services/downloadFile";
import { api } from "../../api/client";
import { FundraisingFile } from "../../api/types/fundraisingTypes";
import { FileDownloadInfo } from "../../store/state/messages/types";
import { impersonationSelector } from "../../store/state/user/selectors";
import { FundraisingDocumentActionsContextProvider } from "./FundraisingDocumentActionsContext";
import FundraisingDocumentsGrid from "./FundraisingDocumentsGrid";

interface Props {
  files: FundraisingFile[];
  fundraisingId: string;
}

const getDownloadUrlForAllFundraisingDocuments = withErrorHandling(
  api.fundraising.getDownloadUrlForAllFundraisingDocuments
);

const getFundraisingDocumentDownloadInfo = withErrorHandling(api.fundraising.getFundraisingDocumentDownloadInfo);

const FundraisingDocuments = ({ files, fundraisingId }: Props) => {
  const { sendNotificationError } = useNotificationContext();
  const isImpersonation = useSelector(impersonationSelector);

  const [isDownloading, setIsDownloading] = useState(false);
  const [previewFileInfo, setPreviewFileInfo] = useState<FileDownloadInfo>();

  if (files.length === 0) {
    return null;
  }

  const handleDownloadAll = async () => {
    setIsDownloading(true);
    const [url, error] = await getDownloadUrlForAllFundraisingDocuments(fundraisingId);
    setIsDownloading(false);

    if (error) {
      sendNotificationError("The documents could not be downloaded");
      logError(error, "[FundraisingDocuments] getDownloadUrlForAllFundraisingDocuments");
      return;
    }

    if (isImpersonation) {
      downloadFileFromUrlViaAxios(url);
    } else {
      downloadFileFromUrl(url);
    }
  };

  const handleShowFilePreview = async (fileId: string) => {
    const [fileInfo, error] = await getFundraisingDocumentDownloadInfo(fundraisingId, fileId);
    if (error) {
      sendNotificationError("The document could not be previewed");
      logError(error, "[FundraisingDocuments] getFundraisingDocumentDownloadInfo");
      return;
    }

    setPreviewFileInfo(fileInfo);
  };

  return (
    <FundraisingDocumentActionsContextProvider showFilePreview={handleShowFilePreview}>
      <Box display="flex" py={2} justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Documents</Typography>
        <Breakpoint from="lg">
          <LoadingButton
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleDownloadAll}
            loading={isDownloading}
          >
            Download all
          </LoadingButton>
        </Breakpoint>
      </Box>

      <FundraisingDocumentsGrid files={files} />

      {previewFileInfo && (
        <PreviewFileDialog
          url={previewFileInfo.downloadUrl}
          fileName={previewFileInfo.fileName}
          documentViewSessionId={previewFileInfo.documentViewSessionId}
          onClose={() => setPreviewFileInfo(undefined)}
        />
      )}
    </FundraisingDocumentActionsContextProvider>
  );
};

export default FundraisingDocuments;
