import { useTheme } from "@mui/material";

interface Props {
  progress: number;
}

const JCurveIcon = ({ progress }: Props) => {
  const theme = useTheme();

  const filledParts = Math.floor(progress * 5);
  const fillColor = filledParts > 2 ? theme.palette.success.main : theme.palette.error.main;
  const dashColor = "#bdbdbd";
  const dashArray = "4 4";

  return (
    <svg viewBox="0 0 65 16" width="65" height="16">
      <path
        fill="none"
        d="M 1 10.6878 C 16.2768 9.95114 16.273 14.5999 26.7071 14.5999"
        strokeWidth={2}
        stroke={filledParts > 0 ? fillColor : dashColor}
        strokeDasharray={filledParts > 0 ? undefined : dashArray}
      />
      <path
        fill="none"
        d="M 26.7071 14.5999 C 31.6062 14.5999 34.7029 13.75 36.9953 12.4999"
        strokeWidth={2}
        stroke={filledParts > 1 ? fillColor : dashColor}
        strokeDasharray={filledParts > 1 ? undefined : dashArray}
      />
      <path
        fill="none"
        d="M 36.9953 12.4999 C 42.0956 9.71848 43.2142 4.95582 51.3431 3.16462"
        strokeWidth={2}
        stroke={filledParts > 2 ? fillColor : dashColor}
        strokeDasharray={filledParts > 2 ? undefined : dashArray}
      />
      <path
        fill="none"
        d="M 51.3431 3.16462 C 56.1702 2.10098 59.6444 1.3999 65 1.3999"
        strokeWidth={2}
        stroke={filledParts > 3 ? fillColor : dashColor}
        strokeDasharray={filledParts > 3 ? undefined : dashArray}
      />
    </svg>
  );
};

export default JCurveIcon;
