import BankIcon from "@mui/icons-material/AccountBalanceOutlined";
import GroupIcon from "@mui/icons-material/GroupOutlined";
import PersonIcon from "@mui/icons-material/PersonOutlined";
import SecurityIcon from "@mui/icons-material/SecurityOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import { Box, MenuList, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { UserActions } from "../../../store/state/user/actions";
import { investorsSelector, workspaceInvestorSelector } from "../../../store/state/user/selectors";
import FeatureBoundary from "../../common/FeatureBoundary";
import InvestorsMenuSelect from "./InvestorsMenuSelect";
import MenuPageItem from "./MenuPageItem";
import SettingsMenuSection from "./SettingsMenuSection";

const SettingsMenu = () => {
  const dispatch = useDispatch();

  const routeMatch = useMatch("/:client/settings/lp/:page");
  const page = routeMatch?.params.page;

  const investors = useSelector(investorsSelector);
  const workspaceInvestor = useSelector(workspaceInvestorSelector);

  const handleInvestorSelected = (investorId: string) => dispatch(UserActions.UpdateWorkspace(investorId));

  return (
    <Stack p={2} spacing={2.5} width="100%">
      <SettingsMenuSection title="Profile settings">
        <MenuList>
          <MenuPageItem title="My Profile" Icon={PersonIcon} pageName="profile" currentPage={page} />
          <MenuPageItem
            title="Password & Authentication"
            Icon={SecurityIcon}
            pageName="password-auth"
            currentPage={page}
          />
        </MenuList>
      </SettingsMenuSection>

      <SettingsMenuSection title="Investor settings">
        {workspaceInvestor && (
          <Box pt={1}>
            <InvestorsMenuSelect
              selectedId={workspaceInvestor.investorId}
              investors={investors}
              onChange={handleInvestorSelected}
            />
          </Box>
        )}
        <MenuList>
          <FeatureBoundary required="AccountSettingsViewer">
            <MenuPageItem title="Investor Details" Icon={SettingsIcon} pageName="account" currentPage={page} />
          </FeatureBoundary>
          <FeatureBoundary required="BankAccountsDetailsViewer">
            <MenuPageItem title="Bank Accounts" Icon={BankIcon} pageName="bank" currentPage={page} />
          </FeatureBoundary>
          <FeatureBoundary required="Membership">
            <MenuPageItem title="Members" Icon={GroupIcon} pageName="members" currentPage={page} />
          </FeatureBoundary>
        </MenuList>
      </SettingsMenuSection>
    </Stack>
  );
};

export default SettingsMenu;
