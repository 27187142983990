import {
  CalculateByField,
  DateRangeType,
  IrrCashFlowFrequency,
  MeasureConfigurationType,
  TabularDataField,
  TabularFieldConfiguration,
  TabularFieldType,
  UserMeasureConfigurations,
} from "../../reporting/api/biClient.types";
import { formatMoney, formatPercentage } from "../../utilities/formatters";

//This data will come from dashboard widgets builder and metadata
export interface TabularDataFieldConfig {
  title: string;
  format?: (value: string | number | undefined) => string;
  calculateTotal?: boolean;
  hidden?: boolean;
  isKey?: boolean;
  align?: "left" | "right" | "center";
  component?: JSX.Element;
}

export type TabularDataFieldConfigMap = TabularDataField & { config: TabularDataFieldConfig };

const tryFormatMoney = (value: string | number | undefined, invertValue?: boolean) => {
  if (value === undefined) {
    return "-";
  }

  if (typeof value === "string") {
    const parsed = parseFloat(value);
    if (isNaN(parsed)) {
      return "-";
    }
    value = parsed;
  }

  if (value === 0 || Object.is(value, -0)) {
    return "-";
  }

  if (invertValue) {
    value = -value;
  }

  return formatMoney(value, "USD", { currencySign: "accounting" });
};

const tryFormatPercentage = (value: string | number | undefined | null) => {
  if (value === undefined || value === null) {
    return "-";
  }

  if (typeof value === "string") {
    value = parseFloat(value);
    if (isNaN(value)) {
      return "-";
    }
  }

  if (value === 0 || Object.is(value, -0)) {
    return "-";
  }

  return formatPercentage(value, { maximumFractionDigits: 2 });
};

const investorNameField: TabularDataFieldConfigMap = {
  guid: "1a019e1b-14d7-4032-90ef-0843ee8193cd",
  fieldType: TabularFieldType.Dimension,
  dimension: {
    dimensionName: "InvestorName",
  },
  config: { title: "Investor" },
};

const entityNoField: TabularDataFieldConfigMap = {
  guid: "67ba5e55-2458-4686-8a69-e735ff7601e6",
  fieldType: TabularFieldType.Dimension,
  dimension: {
    dimensionName: "EntityNo",
  },
  config: { title: "No.", hidden: true, isKey: true },
};

const communicationGroupNameField: TabularDataFieldConfigMap = {
  guid: "b7a4ee6f-940c-4cb7-b4f9-091a254c7bb8",
  fieldType: TabularFieldType.Dimension,
  dimension: {
    dimensionName: "CommunicationGroupName",
  },
  config: { title: "Fund" },
};

const netIRRField: TabularDataFieldConfigMap = {
  guid: "e50a5cf3-1ffe-43fc-b116-bf8efaf33985",
  fieldType: TabularFieldType.Measure,
  measure: {
    measureName: "NetIrr",
    guid: "28ea2ef0-c6a4-4a8f-9cfd-e0fcf07ef37e",
    dateRangeType: DateRangeType.EndingBalance,
    conditions: [],
    customLabel: "Net IRR",
    calculateByField: CalculateByField.Lcy,
  },
  config: {
    title: "Net IRR",
    format: (value) => tryFormatPercentage(value),
    align: "right",
  },
};

const balanceField: TabularDataFieldConfigMap = {
  guid: "38de34c3-5b49-47e6-9c0f-3c20539864fa",
  fieldType: TabularFieldType.Measure,
  measure: {
    measureName: "PartnersCapital",
    guid: "6a0cd764-30de-4e33-a9b5-e0b56cbca3bf",
    dateRangeType: DateRangeType.EndingBalance,
    conditions: [],
    customLabel: "Balance",
    calculateByField: CalculateByField.Lcy,
  },
  config: {
    title: "Balance",
    calculateTotal: true,
    format: (value) => tryFormatMoney(value, true),
    align: "right",
  },
};

const unfundedField: TabularDataFieldConfigMap = {
  guid: "cc999e43-e6fe-4c6e-96ea-87f66f7e168c",
  fieldType: TabularFieldType.Measure,
  measure: {
    measureName: "Unfunded",
    guid: "89828d38-b718-494d-994a-f7633246af84",
    dateRangeType: DateRangeType.EndingBalance,
    conditions: [],
    customLabel: "Unfunded",
    calculateByField: CalculateByField.Lcy,
  },
  config: {
    title: "Unfunded",
    calculateTotal: true,
    format: (value) => tryFormatMoney(value),
    align: "right",
  },
};
const commitmentField: TabularDataFieldConfigMap = {
  guid: "645718e8-1f2b-439c-9381-9a04389e9c61",
  fieldType: TabularFieldType.Measure,
  measure: {
    measureName: "Commitment",
    guid: "1d86b4b7-1d44-45a5-96af-cd2dc36a9ce6",
    dateRangeType: DateRangeType.EndingBalance,
    conditions: [],
    customLabel: "Commitment",
    calculateByField: CalculateByField.Lcy,
  },
  config: {
    title: "Commitment",
    calculateTotal: true,
    format: (value) => tryFormatMoney(value),
    align: "right",
  },
};

const contributionsField: TabularDataFieldConfigMap = {
  guid: "bc9e0bf7-0da0-4143-9c6c-a400b23137e6",
  fieldType: TabularFieldType.Measure,
  measure: {
    measureName: "Contributions",
    guid: "0c919427-78be-4dd4-b748-6334f06ca501",
    dateRangeType: DateRangeType.EndingBalance,
    conditions: [],
    customLabel: "Contributions",
    calculateByField: CalculateByField.Lcy,
  },
  config: {
    title: "Contributions",
    calculateTotal: true,
    format: (value) => tryFormatMoney(value, true),
    align: "right",
  },
};

const distributionsField: TabularDataFieldConfigMap = {
  guid: "8e2af8ca-8f19-4062-9694-8d5933e44276",
  fieldType: TabularFieldType.Measure,
  measure: {
    measureName: "Distributions",
    guid: "ca9a3013-5e89-4bc5-90b7-d462f776861d",
    dateRangeType: DateRangeType.EndingBalance,
    conditions: [],
    customLabel: "Distributions",
    calculateByField: CalculateByField.Lcy,
  },
  config: {
    title: "Distributions",
    calculateTotal: true,
    format: (value) => tryFormatMoney(value, true),
    align: "right",
  },
};

const dashboardBaseBalanceFields: TabularDataFieldConfigMap[] = [
  balanceField,
  unfundedField,
  commitmentField,
  contributionsField,
  distributionsField,
  netIRRField,
];

const getBalanceFieldSet = (includeGrouppingInvestorField: boolean) => {
  return includeGrouppingInvestorField
    ? [investorNameField, communicationGroupNameField, ...dashboardBaseBalanceFields]
    : [communicationGroupNameField, ...dashboardBaseBalanceFields];
};

const mapFieldsToTabularConfiguration: (fields: TabularDataFieldConfigMap[]) => TabularFieldConfiguration[] = (
  fields
) =>
  fields.map((f): TabularFieldConfiguration => {
    let measure: UserMeasureConfigurations | undefined = undefined;
    if (f.measure) {
      const isIrr = f.measure.measureName.toLowerCase().includes("irr");
      measure = <UserMeasureConfigurations>{
        guid: f.measure.guid,
        name: f.measure.measureName,
        dateRange: f.measure.dateRangeType,
        calculateByField: f.measure.calculateByField,
        customLabel: undefined,
        standalone: undefined,
        reverseSign: undefined,
        blankZero: undefined,
        format: undefined,
        useCustomConditions: undefined,
        customConditions: undefined,
        linkedConditions: undefined,
        hideAggregation: undefined,
        type: isIrr ? MeasureConfigurationType.Irr : MeasureConfigurationType.Default,
        cashFlowFrequency: isIrr ? IrrCashFlowFrequency.Daily : undefined,
      };
    }

    return {
      guid: f.guid,
      fieldType: f.fieldType,
      dimension: f.dimension ? { name: f.dimension?.dimensionName } : undefined,
      measure,
    };
  });

export {
  balanceField,
  commitmentField,
  communicationGroupNameField,
  contributionsField,
  dashboardBaseBalanceFields,
  distributionsField,
  entityNoField,
  getBalanceFieldSet,
  investorNameField,
  mapFieldsToTabularConfiguration,
  netIRRField,
  unfundedField,
};
