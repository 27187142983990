type FormatterOptions = Omit<Intl.NumberFormatOptions, "style" | "currency"> & NumberFormatExtensions;

export type NumberFormatFactor = "Thousands" | "Millions" | "Billions";

interface NumberFormatExtensions {
  factor?: NumberFormatFactor;
}

interface NumberFormatConfig {
  thousandSeparator: string;
  decimalSeparator: string;
}

const formatWithFactor = (formatter: Intl.NumberFormat, value: number, factor: NumberFormatFactor) => {
  const postfix = factor === "Thousands" ? "K" : factor === "Millions" ? "MM" : "B";
  const factorValue = factor === "Thousands" ? 1_000 : factor === "Millions" ? 1_000_000 : 1_000_000_000;
  const resultValue = value / factorValue;
  const parts = formatter.formatToParts(resultValue);
  const numericParts = parts.filter((part) => part.type === "integer" || part.type === "fraction");
  const lastNumericPart = numericParts[numericParts.length - 1];
  return parts.reduce((result, part) => result + (part === lastNumericPart ? part.value + postfix : part.value), "");
};

export const formatNumber = (value: number, options?: FormatterOptions, locale?: string) => {
  const formatter = new Intl.NumberFormat(locale ?? navigator.language, { ...options, style: "decimal" });
  return options?.factor ? formatWithFactor(formatter, value, options.factor) : formatter.format(value);
};

export const formatMoney = (value: number, currencyCode: string, options?: FormatterOptions, locale?: string) => {
  const formatter = new Intl.NumberFormat(locale ?? navigator.language, {
    ...options,
    style: "currency",
    currency: currencyCode,
  });
  return options?.factor ? formatWithFactor(formatter, value, options.factor) : formatter.format(value);
};

export const formatPercentage = (value: number, options?: FormatterOptions, locale?: string) => {
  const formatter = new Intl.NumberFormat(locale ?? navigator.language, { ...options, style: "percent" });
  return options?.factor ? formatWithFactor(formatter, value, options.factor) : formatter.format(value);
};

export const getNumberFormatConfig = (locale?: string): NumberFormatConfig => {
  const parts = new Intl.NumberFormat(locale ?? navigator.language).formatToParts(1000.2);
  const thousandSeparator = parts.find((part) => part.type === "group")?.value ?? "";
  const decimalSeparator = parts.find((part) => part.type === "decimal")?.value ?? "";
  return { thousandSeparator, decimalSeparator };
};

export const formatDurationInSeconds = (totalSeconds: number): string => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return [
    hours > 0 ? hours.toString().padStart(2, "0") : "", // Omit hours if zero
    (minutes ?? 0).toString().padStart(2, "0"),
    (seconds ?? 0).toString().padStart(2, "0"),
  ]
    .filter(Boolean)
    .join(":");
};
