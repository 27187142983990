import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { IconButton, Stack, Typography } from "@mui/material";

interface Props {
  pageSize: number;
  currentPage: number;
  records: number;
  navigateBack: () => void;
  navigateForward: () => void;
}

export const PageScrollerComponent = ({ pageSize, currentPage, records, navigateBack, navigateForward }: Props) => {
  const canScrollBackward = currentPage > 0;
  const canScrollForward = (currentPage + 1) * pageSize < records;
  const currentPageStartRecord = currentPage * pageSize + 1;
  const currentPageEndRecord = Math.min(currentPage * pageSize + pageSize, records);

  const currentShownRecords =
    currentPageStartRecord === currentPageEndRecord
      ? `${currentPageStartRecord}`
      : `${currentPageStartRecord}-${currentPageEndRecord}`;

  return (
    <Stack direction="row" alignItems="center">
      <IconButton disabled={!canScrollBackward} onClick={navigateBack} color="secondary">
        <ChevronLeftRoundedIcon />
      </IconButton>
      <Typography color="text.secondary">{records > 0 ? `${currentShownRecords} of ${records}` : "..."}</Typography>
      <IconButton disabled={!canScrollForward} onClick={navigateForward} color="secondary">
        <ChevronRightRoundedIcon />
      </IconButton>
    </Stack>
  );
};

export default PageScrollerComponent;
