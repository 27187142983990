import { DocumentViewEvent, PageViewEventData } from "../../../api/clients/documentEventsApi";

interface PageViewEvent {
  action: "start" | "stop";
  page: number;
  timestamp: number;
}

const significantPageViewDurationSec = 2;

class PageViewTracker {
  private sessionId: string | undefined = undefined;
  private numPages: number = 0;
  private eventsBuffer: PageViewEvent[] = [];

  startSession(sessionId: string, numPages: number) {
    this.sessionId = sessionId;
    this.numPages = numPages;
    this.eventsBuffer = [];
  }

  stopSession() {
    this.sessionId = undefined;
    this.numPages = 0;
    this.eventsBuffer = [];
  }

  trackPageViewStart(page: number) {
    const lastEvent = this.getLastStartedEvent();
    if (lastEvent) {
      this.eventsBuffer.push({
        action: "stop",
        page: lastEvent.page,
        timestamp: Date.now(),
      });
    }

    this.eventsBuffer.push({ action: "start", page, timestamp: Date.now() });
  }

  trackPageViewStop(page: number) {
    this.eventsBuffer.push({ action: "stop", page, timestamp: Date.now() });
  }

  eventsBufferToPageViews(): DocumentViewEvent<PageViewEventData>[] {
    if (!this.sessionId) {
      return [];
    }

    if (this.eventsBuffer.length === 0) {
      return [];
    }

    const pageViewRequests: DocumentViewEvent<PageViewEventData>[] = [];

    for (let i = 0; i < this.eventsBuffer.length; i += 1) {
      const event = this.eventsBuffer[i];
      const nextEventTimestamp = this.eventsBuffer[i + 1]?.timestamp ?? Date.now();

      if (event?.action === "start" && nextEventTimestamp !== undefined) {
        const durationSec = Math.round((nextEventTimestamp - event.timestamp) / 1000);

        if (durationSec >= significantPageViewDurationSec) {
          pageViewRequests.push({
            sessionId: this.sessionId,
            eventData: {
              page: event.page,
              pagesNumber: this.numPages,
              duration: durationSec,
            },
          });
        }
      }
    }

    this.clearBufferAndRestartLastPageView();

    return pageViewRequests;
  }

  private clearBufferAndRestartLastPageView() {
    const lastEvent = this.getLastStartedEvent();
    this.eventsBuffer =
      lastEvent !== undefined ? [{ action: "start", page: lastEvent.page, timestamp: Date.now() }] : [];
  }

  private getLastStartedEvent() {
    const lastEvent = this.eventsBuffer[this.eventsBuffer.length - 1];
    return lastEvent?.action === "start" ? lastEvent : undefined;
  }
}

// Using singleton: the user should not view multiple documents at once
export const pageViewTracker = new PageViewTracker();
