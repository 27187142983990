import { Component, ErrorInfo, ReactNode } from "react";
import { logError } from "../logging";
import Error from "./Error";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logError(error, `[Stack] ${errorInfo.componentStack}`);
    this.setState({ hasError: true, error });
  }

  override render() {
    if (this.state.hasError) {
      return <Error title="Unexpected error" />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
