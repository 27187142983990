import { Maybe } from "../types";
import { formatFileSize, getFileExtension } from "./fileHelper";
import { tryGetUrlWithProtocol } from "./urlHelper";

export interface ValidationResult {
  isValid: boolean;
  error: string;
}

export const validResult = (): ValidationResult => ({ isValid: true, error: "" });
export const invalidResult = (error: string): ValidationResult => ({ isValid: false, error });

export type Validator<T> = (value: T) => ValidationResult;

export const combineValidators =
  <T>(...validators: Validator<T>[]): Validator<T> =>
  (value: T) => {
    for (const validator of validators) {
      const result = validator(value);
      if (!result.isValid) {
        return result;
      }
    }

    return validResult();
  };

export const requiredValidator: Validator<string> = (value) =>
  value.trim() ? validResult() : invalidResult("Value cannot be empty");

export const definedValidator =
  <V>(message?: string): Validator<Maybe<V>> =>
  (value) =>
    value !== undefined && value !== null ? validResult() : invalidResult(message ?? "Value cannot be empty");

export const minCharactersValidator =
  (minChars: number): Validator<string> =>
  (value) =>
    value.length >= minChars ? validResult() : invalidResult(`Value cannot be shorter than ${minChars} characters`);

export const maxCharactersValidator =
  (maxChars: number): Validator<string> =>
  (value) =>
    value.length <= maxChars ? validResult() : invalidResult(`Value cannot be longer than ${maxChars} characters`);

export const regexValidator =
  (regex: RegExp, message: string): Validator<string> =>
  (value) =>
    !value || regex.test(value) ? validResult() : invalidResult(message);

export const emailValidator: Validator<string> = (value) =>
  !value || /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value) ? validResult() : invalidResult("Invalid email address");

export const zipCodeValidator: Validator<string> = (value) =>
  !value || /^\d{5}$/.test(value) ? validResult() : invalidResult("Invalid ZIP code");

export const ibanValidator: Validator<string> = (value) =>
  !value || /^[A-Z]{2}\d{2}[A-Z\d]{10,30}$/.test(value) ? validResult() : invalidResult("Invalid IBAN");

export const bicCodeValidator: Validator<string> = (value) =>
  !value || /^[A-Z]{6}[A-Z\d]{2,5}$/.test(value) ? validResult() : invalidResult("Invalid SWIFT/BIC");

export const routingNumberValidator: Validator<string> = (value) =>
  !value || /^\d{9}$/.test(value) ? validResult() : invalidResult("Invalid routing number");

export const swiftCompliantCharsValidator: Validator<string> = (value) =>
  !value || /^[\sA-Za-z\d+-:(),.'?/]+$/.test(value)
    ? validResult()
    : invalidResult("Value contains characters not supported by SWIFT");

export const uuidValidator: Validator<string> = (value) =>
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(value)
    ? validResult()
    : invalidResult("Invalid UUID");

export const uniqueValidator =
  <V>(message: string, existingValues: V[]): Validator<V> =>
  (value) => {
    const sanitizedValue = typeof value === "string" ? (value.trim() as V) : value;
    return existingValues.includes(sanitizedValue) ? invalidResult(message) : validResult();
  };

export const nonEmptyArrayValidator =
  (message: string): Validator<unknown[]> =>
  (value) =>
    value.length > 0 ? validResult() : invalidResult(message);

export const websiteUrlValidator: Validator<string> = (value) => {
  if (!value) {
    return validResult();
  }
  const url = tryGetUrlWithProtocol(value);
  return url ? validResult() : invalidResult("Invalid website URL");
};

const invalidFileNameChars = ["/", "\\", ":", "|", "?", "*", "<", ">"];

export const fileNameValidator =
  (fieldName = "File name"): Validator<string> =>
  (value) => {
    if (!value) {
      return validResult();
    }

    if (!value.trim()) {
      return invalidResult(`${fieldName} cannot contain only whitespaces`);
    }

    if (value.length > 255) {
      return invalidResult(`${fieldName} cannot be longer than 255 characters`);
    }

    if (value.startsWith(".")) {
      return invalidResult(`${fieldName} cannot start with '.'`);
    }

    const invalidChar = invalidFileNameChars.find((ch) => value.includes(ch));
    if (invalidChar) {
      return invalidResult(`${fieldName} cannot contain '${invalidChar}'`);
    }

    return validResult();
  };

export const fileToUploadValidator =
  ({
    maxFileSize,
    acceptedFileExtensions,
  }: {
    maxFileSize: number;
    acceptedFileExtensions: string[];
  }): Validator<File> =>
  (file) => {
    if (file.size > maxFileSize) {
      return invalidResult(`File is too large. The maximum supported size is ${formatFileSize(maxFileSize)}`);
    }

    const fileExtension = getFileExtension(file.name);
    if (!acceptedFileExtensions.includes(fileExtension)) {
      return invalidResult(`${fileExtension ? '"' + fileExtension + '"' : "This"} file format is not supported`);
    }

    return validResult();
  };
