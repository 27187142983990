import { Box, Dialog, DialogContent, DialogTitle, Popover, Theme, useMediaQuery } from "@mui/material";
import { useMemo, useRef } from "react";
import { usePageOrientation } from "../../../hooks/usePageOrientation";
import PdfDocumentWrapper from "./PdfDocumentWrapper";
import PdfPagesContainer from "./PdfPagesContainer";
import PdfThumbnailsContainer from "./PdfThumbnailsContainer";
import PdfViewerControls from "./PdfViewerControls";
import { usePdfViewer } from "./pdfViewerState";
import { pdfViewerStyles } from "./pdfViewerStyles";
import usePageViewTracking from "./usePageViewTracking";

interface Props {
  open: boolean;
  onClose: () => void;
  fileName: string;
  fileSrc: string;
  documentViewSessionId?: string;
}

const PdfViewerDialog = ({ open, onClose, fileName, fileSrc, documentViewSessionId }: Props) => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const pageOrientation = usePageOrientation();

  const {
    viewerState,
    containerRef,
    pageRefs,
    containerSize,
    handleScroll,
    handleKeyDown,
    navigateToPage,
    toggleThumbnails,
    updateCurrentPageInputValue,
    handleDocumentLoaded,
    updateLoadingProgress,
    zoomIn,
    zoomOut,
  } = usePdfViewer();

  usePageViewTracking({
    sessionId: documentViewSessionId,
    numPages: viewerState.numPages,
    currentPageNumber: viewerState.currentPageNumber,
  });

  const dialogTitleRef = useRef<HTMLDivElement | null>(null);

  const pageHeight = useMemo(
    () => (pageOrientation === "landscape" && containerSize?.height ? containerSize.height - 16 : undefined),
    [containerSize?.height, pageOrientation]
  );

  const pageWidth = useMemo(
    () => (pageOrientation === "portrait" && containerSize?.width ? containerSize.width - 16 : undefined),
    [containerSize?.width, pageOrientation]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isSmallScreen}
      fullWidth
      maxWidth="xl"
      onKeyDown={handleKeyDown}
      sx={{ ".MuiPaper-root": { maxHeight: "100vh", height: "100vh" } }}
    >
      <DialogTitle
        component="div"
        ref={dialogTitleRef}
        sx={(t) => ({ py: 1, px: 2.5, borderBottom: `1px solid ${t.palette.divider}` })}
      >
        <PdfViewerControls
          fileName={fileName}
          viewerState={viewerState}
          documentViewSessionId={documentViewSessionId}
          hideFileName={pageOrientation === "portrait"}
          hidePrintButton={pageOrientation === "portrait"}
          onNavigateToPage={navigateToPage}
          onPageInputValueChange={updateCurrentPageInputValue}
          onToggleThumbnails={toggleThumbnails}
          onZoomOut={zoomOut}
          onZoomIn={zoomIn}
          onClose={onClose}
        />
      </DialogTitle>
      <DialogContent ref={containerRef} onScroll={handleScroll} sx={{ px: 0, bgcolor: pdfViewerStyles.bgcolor }}>
        <Box height="100%">
          <PdfDocumentWrapper
            fileSrc={fileSrc}
            viewerState={viewerState}
            onDocumentLoad={handleDocumentLoaded}
            onLoadingProgress={updateLoadingProgress}
          >
            <Popover
              open={viewerState.showThumbnails}
              onClose={() => toggleThumbnails()}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              anchorEl={dialogTitleRef.current}
              sx={{
                "& .MuiPopover-paper": { maxHeight: containerSize?.height },
              }}
            >
              <PdfThumbnailsContainer viewerState={viewerState} />
            </Popover>

            <PdfPagesContainer
              viewerState={viewerState}
              pageRefs={pageRefs}
              pageHeight={pageHeight}
              pageWidth={pageWidth}
            />
          </PdfDocumentWrapper>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PdfViewerDialog;
