import { ListItemIcon, MenuItem, Select, Typography } from "@mui/material";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useClientContext } from "../../contexts/ClientContext";
import { permissionsSelector } from "../../store/state/user/selectors";

export const ClientsSelector = () => {
  const { clientCode } = useClientContext();
  const permissions = useSelector(permissionsSelector);
  const navigate = useNavigate();

  const clientPortalSettings = useCallback(
    (clientCode: string) => permissions.find((ps) => ps.clientCode === clientCode)?.portalSettings,
    [permissions]
  );

  return (
    <Select
      readOnly={permissions.length === 1}
      contentEditable={false}
      value={clientCode}
      variant="outlined"
      sx={(theme) => ({
        ".MuiSelect-select": {
          py: theme.spacing(0.5),
        },
        ".MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        ".MuiSelect-icon": {
          display: permissions.length === 1 ? "none" : "inline-block",
        },
        [theme.breakpoints.down("sm")]: {
          ml: "0.5rem",
        },
      })}
      renderValue={(value) => {
        const clientLogoUrl = clientPortalSettings(value)?.logoTypeUrl;
        return <img alt="Client Logo" style={{ maxHeight: "32px", marginTop: "4px" }} src={`${clientLogoUrl}`} />;
      }}
      onChange={({ target }) => navigate(`/${target.value}`)}
    >
      {permissions.map((permission) => (
        <MenuItem key={permission.clientCode} value={permission.clientCode}>
          <ListItemIcon>
            <img
              alt="Client Logo Small"
              style={{ width: "20px", height: "20px" }}
              src={`${clientPortalSettings(permission.clientCode)?.logoMarkUrl}`}
            />
          </ListItemIcon>
          <Typography>{permission.clientTitle}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default ClientsSelector;
